import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const GeneralTextWrapper = styled.div`
  h2 {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    h2 {
      margin-top: 50px;
      margin-bottom: 35px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    h2 {
      margin-top: 70px;
      margin-bottom: 30px;
    }
  `)}
`;

export const CustomTextWrapper = styled.div`
  h2 {
    margin-top: 40px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    h2 {
        margin-top: 50px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    h2 {
        margin-top: 60px;
    }
  `)}
`;
