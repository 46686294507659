import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Download = styled.div`
  width: fit-content;
  margin-top: 50px;

  a {
    width: fit-content;
    background-color: ${({ theme }) => theme.palette.primaryHighlight};

    &:hover {
      background-color: ${({ theme }) => theme.palette.secondaryHighlight};
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const ContentWrapper = styled.div`
  display: block;
  padding: 0px ${({ theme }) => theme.layout.gutter}px;
  margin: 140px auto 80px;
  max-width: ${({ theme }) => theme.layout.contentMaxWidth}px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin: 100px auto 120px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin: 60px auto 160px;
  `)}
`;

export const TocItems = styled.ul`
  border-top: 2px solid ${({ theme }) => theme.palette.secondary};
  border-bottom: 2px solid ${({ theme }) => theme.palette.secondary};
  margin-top: 12px;
  padding: 15px 0px;
  column-count: 1;
  column-gap: 120px;
  display: block;
  column-width: auto;
  width: 100%;
  position: relative;
`;

export const TocItem = styled.li`
  color: ${({ theme }) => theme.palette.primary};
  display: inline-block;
  padding-right: 10px;
  width: 100%;

  a {
    font-weight: 700;
    font-size: 18px;
    padding: 16px 0px;
    display: flex;
    line-height: 1.2;
    transition: color 0.3s;

    span {
      width: 100%;
    }

    svg {
      margin-left: 10px;
      min-width: 16px;
      width: 16px;
      height: 14.4px;
      transition: transform 0.3s;
      bottom: -4px;
      position: relative;

      polygon {
        transition: fill 0.3s;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.palette.secondary};

      svg {
        transform: translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.secondaryHighlight};
        }
      }
    }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 20px;
    svg {
      margin-left: 10px;
      min-width: 25px;
      width: 20px;
      height: 18px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 22px;
  `)}

  }
`;

export const TocWrapper = styled.nav`
  color: ${({ theme }) => theme.palette.primary};
  margin-top: 32px;

  h2 {
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 60px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-top: 64px;
  `)}
`;
