import React from 'react';
import { graphql } from 'gatsby';
import { Layout, LinkButton, GeneralHero } from '@components';
import { Content, ContentGutter, IntroWrapper } from '@styles';
import { getKeyValue, compileRichText } from '@utils';
import { LIGHT, XLARGE } from '@utils/vars';
import { GeneralTextWrapper } from './general-content.styles';
import { Download } from './plan.styles';

const GeneralContentTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    content, title, summary, heroImage, releaseDate, dateLabel,
    documentFileDownload, documentDownloadText,
  } = contentEntry || {};
  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};
  const hasImg = heroImage && heroImage.fluid;

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      fullWidth
      navTheme={LIGHT}
      doNotIndex={doNotIndex}
    >
      <GeneralHero
        title={title}
        heroImage={heroImage}
        intro={summary}
        releaseDate={releaseDate}
        dateLabel={dateLabel}
        documentFileDownload={documentFileDownload}
        documentDownloadText={documentDownloadText}
      />
      <ContentGutter>
        <Content>
          {!hasImg && summary && (
            <IntroWrapper>
              {compileRichText({ textTheme: 'primary', size: 'large', locale })(summary)}
            </IntroWrapper>
          )}
          {content && (
            <GeneralTextWrapper>
              {compileRichText({ textTheme: 'primary', size: 'small', locale })(content)}
            </GeneralTextWrapper>
          )}
          {documentFileDownload && documentDownloadText && documentFileDownload.file
          && documentFileDownload.file.url && (
            <Download>
              <LinkButton
                href={documentFileDownload.file.url}
                size={XLARGE}
                textTheme="white"
              >
                {documentDownloadText}
              </LinkButton>
            </Download>
          )}
        </Content>
      </ContentGutter>
    </Layout>
  );
};

export default GeneralContentTemplate;

export const generalPageQuery = graphql`
  query GeneralContentBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulGeneralContent {
          title
          dateLabel
          releaseDateMeta: releaseDate
          releaseDate(formatString: "MMMM DD, YYYY")
          summary {
            raw
          }
          content {
            raw
            references {
              ... on ContentfulForm {
                contentful_id
                formSize
                formTheme
                fastactionNoLogin
                databag
                formType
                formUrl
                hideAdditionalInformationTitle
                hideFormTitle
                mobileAutofocus
                ngpFormName
                internalFormTitle
                internal {
                  type
                }
              }
              ... on ContentfulSocialEmbed {
                caption {
                  raw
                }
                type
                contentful_id
                contentfulid
                internal {
                  type
                }
              }
              ... on ContentfulAsset {
                contentful_id
                id
                __typename
                file {
                  url
                  contentType
                }
                internal {
                  type
                }
                title
                description
                fluid(quality: 90, maxWidth: 974) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              ... on ContentfulPage {
                contentful_id
                id
                __typename
                pageUrl
                internal {
                  type
                }
              }
              ... on ContentfulTwoColumns {
                contentful_id
                id
                __typename
                internal {
                  type
                }
                rightColumn {
                  raw
                }
                rightMedia {
                  contentful_id
                  id
                  __typename
                  description
                  id
                  fluid(quality: 90, maxWidth: 768) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                leftColumn {
                  raw
                }
                leftMedia {
                  contentful_id
                  id
                  __typename
                  description
                  id
                  fluid(quality: 90, maxWidth: 768) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 974) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          documentDownloadText
          documentFileDownload {
            file {
              url
            }
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
